/* eslint-disable no-param-reassign */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { identity, indexOf, isEqual, pickBy } from 'lodash-es';
import { APIErrorResponse, Dictionary, IntersectionObserverService, round } from 'asap-team/asap-tools';

import type { BuyAnotherHouse, Fees, Lead, RequestedPropertyType } from '@core/types';

// Consts
import { BUY_ANOTHER_HOUSE, TOOLTIP, TOOLTIP_CONFIG } from '@consts/consts';
import { ANNUAL_INCOME } from '@consts/annual-income';
import { MONTHLY_SPENDINGS } from '@consts/montly-spendings';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@UntilDestroy()
@Component({
  selector: 'buy-another-house-info',
  templateUrl: './buy-another-house-info.component.html',
  styleUrls: ['./buy-another-house-info.component.sass'],
})
export class BuyAnotherHouseInfoComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('sticky') sticky: ElementRef<HTMLInputElement>;

  @Output() emitHouseInfo: EventEmitter<BuyAnotherHouse> = new EventEmitter<BuyAnotherHouse>();

  lead: Lead;

  fees: Fees;

  requested_property: RequestedPropertyType;

  form: UntypedFormGroup;

  tooltip: any = TOOLTIP;

  tooltipOptions: Dictionary<any> = TOOLTIP_CONFIG;

  BUY_ANOTHER_HOUSE: Dictionary<RequestedPropertyType> = BUY_ANOTHER_HOUSE;

  ANNUAL_INCOME: number[] = ANNUAL_INCOME;

  MONTHLY_SPENDINGS: number[] = MONTHLY_SPENDINGS;

  addressHash: string = this.route.snapshot.queryParams.address_hash;

  buyAnotherHouseInfo: BuyAnotherHouse;

  scores: { title: string; value: string; type: string }[] = [
    {
      title: 'Bad',
      value: '300 - 579',
      type: 'bad',
    },
    {
      title: 'Poor',
      value: '580 - 619',
      type: 'poor',
    },
    {
      title: 'Fair',
      value: '620 - 679',
      type: 'fair',
    },
    {
      title: 'Good',
      value: '680 - 719',
      type: 'good',
    },
    {
      title: 'Great',
      value: '720 - 850',
      type: 'great',
    },
  ];

  currentLoadTerm: string = '30';

  stickyHeader: boolean = false;

  isHideDownpaymentSlider: boolean = false;

  isShowHomePrice: boolean = false;

  isLoading: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private intersectionObserverService: IntersectionObserverService,
    private sanitizer: DomSanitizer,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      requested_property: [this.requested_property],
      credit_score_value: ['good'],
      loan_term: ['30'],
      annual_income: [null],
      monthly_spendings: [null],
      downpayment_amount: [''],
    });
    // value can't be 0 according to api rules
    this.form.controls.downpayment_amount.patchValue(0, { emitEvent: false });
    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(isEqual),
        debounceTime(200),
      )
      .subscribe((form: Dictionary<any>) => {
        this.updateHouseInfo(form);
      });

    this.leadService.emitDownpaymentData(null);
    this.updateHouseInfo(this.form.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.fees?.currentValue) {
      this.isHideDownpaymentSlider = changes?.report?.currentValue?.selling?.net_equity <= 0;
    }

    if (changes?.lead?.currentValue) {
      const lead: Lead = changes.lead.currentValue;

      this.isShowHomePrice = lead.loan_present;
    }
  }

  ngAfterViewInit(): void {
    this
      .intersectionObserverService
      .create({ target: this.sticky.nativeElement })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (entry: IntersectionObserverEntry) => {
          this.stickyHeader = entry.isIntersecting;
        },
      );
  }

  get sanitizedText(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.lead.affordable_calculator_disclaimer_text);
  }

  private updateHouseInfo(form: Dictionary<any>): void {
    let filtered: Dictionary<any> = pickBy(form, identity);

    Object.keys(form).forEach((index: string) => {
      if ((index === 'annual_income' || index === 'monthly_spendings') && form[index] === 0) {
        filtered = { ...filtered, [index]: 0 };
      }
    });

    const params: any = Object
      .keys(filtered)
      .reduce(
        (previousValue: any, currentValue: string) => {
          switch (currentValue) {
            case 'annual_income': {
              previousValue[currentValue] = this.ANNUAL_INCOME[form[currentValue]].toString();
              break;
            }
            case 'monthly_spendings': {
              previousValue[currentValue] = this.MONTHLY_SPENDINGS[form[currentValue]].toString();
              break;
            }
            default: {
              previousValue[currentValue] = form[currentValue].toString();
            }
          }

          return previousValue;
        },
        {},
      );

    this
      .leadService
      .getAnotherHouseInfo({
        params,
        uid: this.lead.id,
        ...this.addressHash && { addressHash: this.addressHash },
      })
      .pipe(
        untilDestroyed(this),
        catchError((error: APIErrorResponse) => {
          this.isLoading = false;

          return throwError(error);
        }),
      )
      .subscribe((response: BuyAnotherHouse) => {
        const {
          annual_income,
          downpayment_amount,
          downpayment_limit_min,
          home_price,
          monthly_payment,
          monthly_spendings,
          user_equity,
        } = response;

        this
          .form
          .get('annual_income')
          .patchValue(
            this.getIndexOfClosestValue(annual_income, this.ANNUAL_INCOME),
            { onlySelf: true, emitEvent: false },
          );

        this
          .form
          .get('monthly_spendings')
          .patchValue(
            this.getIndexOfClosestValue(monthly_spendings, this.MONTHLY_SPENDINGS),
            { onlySelf: true, emitEvent: false },
          );

        this.leadService.emitDownpaymentData({
          downpayment_limit_min, user_equity, downpayment_amount,
        });
        this.buyAnotherHouseInfo = response;
        this.emitHouseInfo.emit(response);
        this.isShowHomePrice = !!monthly_payment && !!home_price;
        this.isLoading = false;
      });
  }

  ofType(type: string): boolean {
    return this.form.get('requested_property').value === type;
  }

  selectScore(type: string): void {
    if (!type) { return; }

    this.form.get('credit_score_value').patchValue(type);
  }

  selectDownpaymentAmount(value: string): void {
    if (!value) { return; }

    this.form.get('downpayment_amount').patchValue(value);
  }

  selectLoanTerm(value: string): void {
    if (!value) { return; }

    this.currentLoadTerm = value;
    this.form.get('loan_term').patchValue(value);
  }

  getScoreStyle(score: Dictionary): Dictionary<boolean> {
    return {
      active: score.type === this.form?.value?.credit_score_value,
      [score.type]: true,
    };
  }

  getLineStyle(currentValue: number, param: string): Dictionary {
    const value: number = currentValue ?? 0;
    let percent: number = 0;

    if (param === 'annual_income') {
      percent = round(value * (100 / 46), 1);
    }

    if (param === 'monthly_spendings') {
      percent = round(value * (100 / 126), 1);
    }

    if (param === 'downpayment_amount') {
      percent = round(value / 1000, 1);
    }

    return { background: `linear-gradient(to right, #021656 0%, #125dc3 ${percent}%, #d3e1eb ${percent}%, #d3e1eb 100%)` };
  }

  getLabelStyle(value: number, param: string): Dictionary {
    switch (param) {
      case 'annual_income': {
        return { left: `${round(value * (100 / 46), 1)}%` };
      }
      case 'monthly_spendings': {
        return { left: `${round(value * (100 / 126), 1)}%` };
      }
      case 'downpayment_amount': {
        return { left: `${round(value / 1000, 1)}%` };
      }
      default: {
        return {};
      }
    }
  }

  getActualValue(currentValue: number, param: string): number {
    let actualValue: number = 0;

    if (param === 'annual_income') {
      actualValue = ANNUAL_INCOME[currentValue];
    }

    if (param === 'monthly_spendings') {
      actualValue = MONTHLY_SPENDINGS[currentValue];
    }

    return actualValue;
  }

  getIndexOfClosestValue(value: number, numbers: number[]): number {
    const needle: number = value;
    const array: number[] = [...numbers].sort((a: number, b: number) => Math.abs(needle - a) - Math.abs(needle - b));

    return indexOf(numbers, array[0]);
  }

  sliderClicked(): void {
    switch (this.requested_property) {
      case 'primary_home':
        this.gaService.trackSimpleEvent(GoogleEvents.AFFORDABILITY_CALC, 'affordability', 'new_home_tab');
        break;
      case 'secondary_home':
        this.gaService.trackSimpleEvent(GoogleEvents.AFFORDABILITY_CALC, 'affordability', 'second_home_tab');
        break;
      case 'rental_property':
        this.gaService.trackSimpleEvent(GoogleEvents.AFFORDABILITY_CALC, 'affordability', 'invest_tab');
        break;
      default:
        break;
    }
  }

}
