import { Subject, throwError } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APIErrorResponse, Dictionary } from 'asap-team/asap-tools';

import type { IExtraPrincipal, Lead } from '@core/types';

// Consts
import { ADDITIONAL_PRINCIPAL } from '@consts/additional_principal';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@UntilDestroy()
@Component({
  selector: 'pay-more-range',
  templateUrl: './pay-more-range.component.html',
  styleUrls: ['./pay-more-range.component.sass'],
})
export class PayMoreRangeComponent implements OnInit {

  @Input() payData: IExtraPrincipal;

  @Input() lead: Lead;

  destroy$: Subject<unknown> = new Subject();

  range: UntypedFormControl = new UntypedFormControl();

  count: number[] = ADDITIONAL_PRINCIPAL;

  countLength: number = ADDITIONAL_PRINCIPAL.length - 1;

  point: number = 100 / this.countLength;

  loading: boolean = false;

  // Label position
  pointStep: number = 28 / this.countLength;

  lineStyle: string;

  labelPosition: number;

  labelMLPosition: number;

  constructor(
    private leadService: LeadService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    const currentValue: number = ADDITIONAL_PRINCIPAL.indexOf(this.payData?.additional_principal);

    this.range.patchValue(currentValue);
    this.setLineStyle(currentValue);
    this
      .range
      .valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        distinctUntilChanged(),
        mergeMap((value: string) => {
          this.loading = true;

          const { id, address_hash } = this.lead;

          return this.leadService.getExtraPrincipalInfo({ address_hash, uid: id }, ADDITIONAL_PRINCIPAL[value]);
        }),
        catchError((error: APIErrorResponse) => {
          this.loading = false;

          return throwError(error);
        }),
      )
      .subscribe(
        (result: IExtraPrincipal) => {
          this.payData = result;
          this.loading = false;
        },
      );
  }

  private setLineStyle(count: number): void {
    const value: number = count * this.point;

    this.labelPosition = value;
    this.labelMLPosition = 14 - (this.pointStep * count);
    this.lineStyle = `linear-gradient(to right, #021656 0%, #125dc3 ${value}%, #d3e1eb ${value}%, #d3e1eb 100%)`;
  }

  get sliderStyle(): Dictionary {
    return { background: this.lineStyle };
  }

  changeValue(count: string): void {
    this.setLineStyle(+count);
  }

  humanizeSavingDuration(value: number): string {
    if (!value) {
      return ' 0 months';
    }

    const years: number = Math.floor(value / 12);
    const months: number = value % 12;
    let humanizedDate: string = '';

    if (years) {
      humanizedDate = years !== 1 ? ` ${years} years` : ` ${years} year`;
    }

    if (months) {
      humanizedDate += months !== 1 ? ` ${months} months` : ` ${months} month`;
    }

    return humanizedDate;
  }

  sliderClicked(): void {
    this.gaService.trackSimpleEvent(GoogleEvents.USE_SAVINGS_CALC, 'loan');
  }

}
