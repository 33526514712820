import { keys } from 'lodash-es';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Lead } from '@core/types';

// Consts
import { UNSUBSCRIBE_REASON } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { ThemeService } from '@core/helpers/theme/theme.service';

@UntilDestroy()
@Component({
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.sass'],
})
export class UnsubscribeComponent implements OnInit {

  lead: Lead;

  uid: string;

  form: UntypedFormGroup;

  isLoading: boolean = true;

  isSubscribed: boolean;

  UNSUBSCRIBE_REASON: any = UNSUBSCRIBE_REASON;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private leadService: LeadService,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
    this.uid = this.route.snapshot.params.uid;
    this.form = this.fb.group({ reason: [UNSUBSCRIBE_REASON.NOT_INTERESTED, [Validators.required]] });
  }

  private subscribes(): void {
    const { uid } = this.route.snapshot.params;

    this
      .leadService
      .getLead({ uid })
      .subscribe(
        (lead: Lead) => {
          this.lead = lead;
          this.themeService.set();
          this.isSubscribed = lead.subscribed;
          this.isLoading = false;
        },
      );
  }

  getUnsubscribeReasons(): string[] {
    return keys(this.UNSUBSCRIBE_REASON);
  }

  unsubscribeFromDigest(): void {
    const unsubscribe_reason: any = this.form.get('reason').value;

    this.isLoading = true;
    this
      .leadService
      .unsubscribeFromDigest(this.uid, { unsubscribe_reason })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.isSubscribed = !this.isSubscribed;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

}
