import { Subscription } from 'rxjs';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { phoneValidator } from 'asap-team/asap-tools';

import type { Lead, VerifyPhone } from '@core/types';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@UntilDestroy()
@Component({
  templateUrl: './verify-phone-dialog.component.html',
  styleUrls: ['./verify-phone-dialog.component.sass'],
})
export class VerifyPhoneDialogComponent extends SimpleModalComponent<any, Lead> implements OnInit {

  phoneNumber: string;

  uid: string;

  disclaimerNames: string;

  form: UntypedFormGroup;

  action: Subscription;

  errors: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private leadService: LeadService,
    private gaService: GAService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({ phone: [this.phoneNumber, phoneValidator] });
    this.gaService.trackSimpleEvent(GoogleEvents.UPDATES_POPUP, 'subscribe');
  }

  confirm(): void {
    this.gaService.trackSimpleEvent(GoogleEvents.SMS_UNSUBSCRIBE, 'subscribe');

    const payload: VerifyPhone = {
      uid: this.uid,
      phone: this.form.controls.phone.value,
    };

    this.action = this
      .leadService
      .verifyPhone(payload)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (lead: Lead) => {
          this.result = lead;
          this.close();
        },
      );
  }

}
