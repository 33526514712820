import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import type { Lead } from '@core/types';

// Consts
import { GoogleEvents, UNSUBSCRIBE_REASON } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { APIErrorResponse } from 'asap-team/asap-tools';
import { GAService } from '@core/helpers/ga/ga.service';

@Component({
  selector: 'subscribe-to-digest',
  templateUrl: './subscribe-to-digest.component.html',
  styleUrls: ['./subscribe-to-digest.component.sass'],
})
export class SubscribeToDigestComponent implements OnInit, OnChanges {

  @Input() lead: Lead;

  uid: string;

  isLoading: boolean = true;

  isSubscribed: boolean = false;

  showReasonForm: boolean = false;

  UNSUBSCRIBE_REASON: any = UNSUBSCRIBE_REASON;

  form: UntypedFormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private leadService: LeadService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.uid = this.activatedRoute.snapshot.params.uid;
    this.form = this.fb.group({ reason: [UNSUBSCRIBE_REASON.NOT_INTERESTED, [Validators.required]] });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { lead } = changes;

    if (lead.currentValue) {
      this.isSubscribed = lead.currentValue.subscribed;
      this.isLoading = false;
    }
  }

  getUnsubscribeReasons(): string[] {
    return Object.keys(this.UNSUBSCRIBE_REASON);
  }

  subscribeToDigest(): void {
    this.isLoading = true;
    this
      .leadService
      .subscribeToDigest(this.uid)
      .pipe(
        catchError((error: APIErrorResponse) => {
          this.isLoading = false;

          return throwError(error);
        }),
      )
      .subscribe(() => {
        this.isLoading = false;
        this.isSubscribed = true;
      });
  }

  unsubscribeFromDigest(): void {
    const unsubscribe_reason: string = this.form.get('reason').value;

    this.isLoading = true;
    this
      .leadService
      .unsubscribeFromDigest(this.uid, { unsubscribe_reason })
      .pipe(
        catchError((error: APIErrorResponse) => {
          this.isLoading = false;
          this.showReason();

          return throwError(error);
        }),
      )
      .subscribe(() => {
        this.isLoading = false;
        this.isSubscribed = false;
        this.showReason();
        this.gaService.trackSimpleEvent(GoogleEvents.UNSUBSCRIBE);
      });
  }

  showReason(): void {
    this.showReasonForm = !this.showReasonForm;
  }

}
