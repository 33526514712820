import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  Observable,
  of,
  forkJoin,
  Subject,
  throwError,
  iif,
  BehaviorSubject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  shareReplay,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { genericRetryStrategy, Dictionary } from 'asap-team/asap-tools';

import type { Blocks } from '@core/types';

// Constants
import { TRACKING, ROUTE } from '@consts/index';
import { environment } from 'environments/environment';
import { COMMON_TOAST } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { TrackingService } from '@core/helpers/tracking/tracking.service';
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@Injectable({ providedIn: 'root' })
export class DigestDataService {

  public digestLoad: boolean = false;

  private REQUEST_TIMEOUT: number = 1000 * 60 * 3;

  private RETRY_START_DUCRATION: number = 1000 * 60;

  private report: Subject<Blocks> = new Subject<Blocks>();

  private reportUpdating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  reportUpdating$: Observable<boolean> = this
    .reportUpdating
    .asObservable();

  report$: Observable<Blocks> = this
    .report
    .asObservable()
    .pipe(
      distinctUntilChanged(isEqual),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private router: Router,
    private leadService: LeadService,
    private trackingService: TrackingService,
    private toastr: ToastrService,
    private firebaseService: FirebaseService,
  ) {}

  getReportData({ uid, address_hash }: Dictionary): Observable<Blocks | HttpErrorResponse> {
    if (!uid) {
      this.router.navigate([ROUTE.alias.NOT_FOUND]);

      return of(null);
    }

    return this
      .firebaseService
      .reportStatusObserver(uid)
      .pipe(
        switchMap((value: boolean, index: number) => iif(
          () => value,
          this
            .report$
            .pipe(
              tap(() => {
                this.toastr.show(COMMON_TOAST.DATA_UPDATING, '', { timeOut: 0 });
                this.reportUpdating.next(value);
              }),
            ),
          this.getCombinedReport({ uid, address_hash }, index),
        )),
      );
  }

  getCombinedReport({ uid, address_hash }: Dictionary, index: number): Observable<Blocks | HttpErrorResponse> {
    return forkJoin([
      this.leadService.getLead({ uid, address_hash }),
      this.leadService.getInterestAddsUp({ uid, address_hash }),
      this.leadService.getExtraPrincipalInfo({ uid, address_hash }),
      this.leadService.getRentInfo({ uid, address_hash }),
      this.leadService.getAirbnbInfo({ uid, address_hash }),
      iif(() => !environment.production, this.leadService.getInsurance({ uid, address_hash }), of(null)),
      this.leadService.getFees({ uid, address_hash }),
    ])
      .pipe(
        timeout<Blocks>(this.REQUEST_TIMEOUT),
        genericRetryStrategy({
          maxRetryAttempts: 1,
          scalingDuration: this.RETRY_START_DUCRATION,
        }),
        tap((data: Blocks) => {
          if (index) {
            this.reportUpdating.next(false);
            this.toastr.success(COMMON_TOAST.DATA_UPDATED);
          }

          this.updateReport(data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.trackingService.track({ user_uid: uid, ...TRACKING.report_load_error }, false, true).subscribe();
          this.router.navigate([ROUTE.alias.NOT_FOUND], { state: { status: error.status } });

          return throwError(error);
        }),
      );
  }

  updateReport(data: Blocks): void {
    this.report.next(data);
  }

}
